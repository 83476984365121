import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Slider = () => {
  const [groupedCarouselData, setGroupedCarouselData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const getCarouselData = useSelector(
    (state) => state.offerMasterReducer?.getofferMaster
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(getCarouselData)) {
      const enabledData = getCarouselData.filter(
        (item) => item.enabled && item?.placement === "Main"
      );
      const sortedData = [...enabledData].sort(
        (a, b) => parseInt(a.displayOrder) - parseInt(b.displayOrder)
      );

      // Group data for the carousel
      const groupedData = [];
      const length = sortedData.length;

      for (let i = 0; i < length; i++) {
        const currentItem = sortedData[i];
        const nextItem = sortedData[(i + 1) % length]; // Wrap around to the first item
        groupedData.push([currentItem, nextItem]);
      }
      setGroupedCarouselData(groupedData);
    }
  }, [getCarouselData]);

  return (
    <>
      {groupedCarouselData.length && (
        <>
          {isMobile ? (
            <div className="mobile-slider-wrapper">
              <section className="section-hero">
                <div className="container">
                  <div
                    className=" hero justify-content-start"
                    style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
                  >
                    {groupedCarouselData.map((group, index) =>
                      group?.sort((a, b) => a.displayOrder - b.displayOrder)?.map((offerMasterData, idx) => (
                        <div
                          key={index}
                          className=""
                          style={{ display: "inline-block" }}
                        >
                          <Link to={offerMasterData?.link}>
                            <img
                              className="banner_image_width"
                              src={`${process.env.REACT_APP_CLIENT_URL}${offerMasterData?.mobileImage}`}
                              alt="Banner"
                              style={{ width: "100%" }}
                            />
                          </Link>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <Carousel interval={6000}>
              {groupedCarouselData.map((group, index) => (
                <Carousel.Item key={index}>
                  <section className="section-hero pt-4">
                    <div className="container">
                      <div className="row hero justify-content-center">
                        {group
                          ?.sort((a, b) => a.displayOrder - b.displayOrder)
                          ?.map((offerMasterData, idx) => (
                            <div className="col-lg-6" key={idx}>
                              <div className="side-ban-img">
                                <Link to={offerMasterData?.link}>
                                  <img
                                    className="banner_image_width"
                                    src={`${process.env.REACT_APP_CLIENT_URL}${offerMasterData?.image}`}
                                    alt="Banner"
                                  />
                                </Link>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </section>
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </>
      )}
    </>
  );
};

export default Slider;
