/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "./UnlockPoints.scss";
import { useDispatch, useSelector } from "react-redux";
import { onStaticPointsSubmit } from "../../Store/Slices/staticPointSlice";
import img1 from "../../Assets/2x.png";
import img2 from "../../Assets/gift.png";
import img3 from "../../Assets/rupee-star.png";
import img4 from "../../Assets/calender.png";

const UnlockPoints = () => {
  const dispatch = useDispatch();
  const [unlockPoints, setUnlockPoint] = useState();
  const scrollContainerRef = useRef(null);
  const loginAuthData = useSelector((state) => state.loginAuthReducer);
  useEffect(() => {
    if (loginAuthData?.data?.length) {
      dispatch(onStaticPointsSubmit());
    }
  }, []);

  const staticPointsData = useSelector(
    (state) => state.staticPointsReducer?.data
  );

  const imgArr = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img1 },
  ];

  const updatedStaticPoints = Array.isArray(staticPointsData)
    ? staticPointsData.map((item, index) => ({
        ...item,
        img: imgArr[index]?.img,
      }))
    : [];

  const handleClick = (index) => {
    setUnlockPoint(index);
  };

  const handleScrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: 200, // Adjust this value to change the scroll amount
      behavior: "smooth",
    });
  };

  return (
    <div className="dotted-line">
      <section className="py-xl-3 py-2 bg-gray earn">
        <div className="container">
          {Array.isArray(staticPointsData) && staticPointsData.length > 0 && (
            <div key={0} className="mb-3">
              <h5 className="sec-below-head">
                <span className="title">{staticPointsData[0]?.title}</span>
                <span className="subtitle">
                  {staticPointsData[0]?.sub_Title}
                </span>
              </h5>
            </div>
          )}

          <div className="scroll-wrapper">
            <div className="scroll-container" ref={scrollContainerRef}>
              {updatedStaticPoints.length > 0 &&
                updatedStaticPoints.map((item, index) => (
                  <div key={index} className="scroll-item">
                    <div
                      className="earn-box d-flex align-items-center"
                      onClick={() => handleClick(index)}
                    >
                      <img
                        key={index}
                        src={item?.img}
                        className="fa-2x mx-3 unlockIcon d-flex align-items-center"
                        alt={`${index + 1}`}
                      />
                      <p className="m-0 desc d-flex align-item-center w-100 unloacDescription">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <button className="scroll-button" onClick={handleScrollRight}>
              <i class="fa-solid fa-chevron-right fa-chevron-right-color"></i>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UnlockPoints;
