import React from "react";
import img from "../../Assets/is.png";
import { useSelector } from "react-redux";
const Refundable = () => {
  const clientConfig = useSelector((state) => state.clientConfigurationReducer?.clientConfigurationData?.[0]);
  return (
    <div class="d-flex justify-content-start p-4  pt-2 pb-2 mt-3 bg-gray1 bor hai refundalbeCard">
      <div class="lsss mr-4 d-flex align-item-center">
        <img className="w-144 img" src={img} alt="img" />
      </div>
      <div class="earncheckitem">
        {clientConfig?.cartInfo===true? clientConfig?.cartInfoMessage : <></>}
      </div>
    </div>
  );
};
export default Refundable;
