import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onValidateLoginTokenReset } from '../../Store/Slices/validateLoginTokenSlice'

const Logout = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (sessionStorage.getItem("login")) {
            sessionStorage.removeItem("login")
            window.location.href = `https://qa-sso.ai-loyalty.com/Logout/giftStacc12032023/${sessionStorage.getItem("loginSessionToken")}`
            dispatch(onValidateLoginTokenReset())
        } else {
            navigate("/")
        }
    }, [])
    return (
        <>
        </>
    )
}

export default Logout