import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_API_URL,
  headers: {
    "Content-Type": "application/json",
    "partner-code": "UIClient",
    "client-code": 1,
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJwYXJ0bmVyLWNvZGUiOiJVSUNsaWVudCIsImp0aSI6IjJhNjcyMzEwLWQ2ZWItNDRiNy04NTc0LTllNGI4ZmFlODYxYSIsImV4cCI6MTcxMjc1OTIzNiwiaXNzIjoieHl6IiwiYXVkIjoic2RmYXNkZmRzZmFzZF9TRkRhc2RmYXNkZl9EU0FmZ0RHRl9GRFNnc2RmZ3NkZl9HZGZzZ3NkZmdzZGZfR2RzZmdzZGZnX0RzZmcifQ.iuiR52tuI5r6smSBbQC9bULxG1_Nwn3ETxfeCHXPa5I",
  },
});

export default api;
