import React from 'react';
import { GetTranslationData } from '../GetTranslationData/GetTranslationData ';

const TrendingCardHeader = ({ product, categoryName }) => {
    const showing = GetTranslationData("UIAdmin", "showing");
    const results = GetTranslationData("UIAdmin", "results");    
    return (
        <>
            <div className="col-xl-7 col-12 p-trend">
                <h3 className="fill2">{categoryName.length === 1 ? `${categoryName[0]} Products`:"Products"}</h3>
                <p className='showing'>{showing} {product?.length} {results}</p>
            </div>
        </>
    );
};

export default TrendingCardHeader;
