/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onGetClientProductMappingbyId } from "../../Store/Slices/clientProductMappingbyIdSlice";
import { onGetCategoryAll } from "../../Store/Slices/createCategorySlice";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import { onProductByIdSubmit } from "../../Store/Slices/productSlice";

const Header = ({ setModalOpen }) => {
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  const categoryAllData = useSelector(
    (state) => state.userRoleSliceReducer.categoryData
  );
  const productByClientData = useSelector(
    (state) => state.productReducer.productById?.[0]?.products
  );
  const validateLoginTokenData = useSelector(
    (state) => state.validateLoginTokenSliceReducer.data
  );
  let category = [];
  categoryAllData?.forEach((categoryData) => {
    if (categoryData.displayHeader) {
      let data = productByClientData?.find(
        (productData) => productData.id === categoryData.supplierBrandId
      );
      if (data) {
        let products = category[categoryData.name]
          ? category[categoryData.name]
          : [];
        products.push(data);
        category = {
          ...category,
          [categoryData.name]: products,
        };
      }
    }
  });
  const loginAuthData = useSelector((state) => state.loginAuthReducer);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200);
    });
    if (loginAuthData?.data?.length) {
      dispatch(
        onGetClientProductMappingbyId(sessionStorage.getItem("clientCode"))
      );
      dispatch(onGetCategoryAll());
      dispatch(onProductByIdSubmit(sessionStorage.getItem("clientCode")));
    }
  }, []);

  const getInitials = (firstName, lastName) => {
    if (!firstName || !lastName) return "";
    const firstLetter = firstName[0];
    const lastLetter = lastName[0];
    return `${firstLetter}${lastLetter}`;
  };

  return (
    <>
      <DesktopHeader
        scroll={scroll}
        matchCategory={category}
        validateLoginTokenData={validateLoginTokenData}
        getInitials={getInitials}
      />
      <MobileHeader
        scroll={scroll}
        matchCategory={category}
        validateLoginTokenData={validateLoginTokenData}
        getInitials={getInitials}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

export default Header;
/* eslint-enable react-hooks/exhaustive-deps */
