import React from "react";
import FeaturedCard from "./FeaturedCard ";
import img1 from "../../Assets/g8.png"
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const FeaturedGiftCardsSection = () => { 
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlProductId = urlParams.get("productId");
  const parsedProductId = urlProductId && parseInt(urlProductId);
  const FeaturedItem = useSelector((state) => state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping);
  const enabledFeaturedItem = FeaturedItem?.filter(item => item?.isFeatured === true);
  const product = useSelector(state => state.productReducer.productById?.[0]?.products)
  const matchedProducts = enabledFeaturedItem
  ?.map((item) => product?.find((prod) => prod.id === item.productId))
  .filter((item) => item && item.id !== parsedProductId); 
  const calculatePoints = (item) => {
    const productId = item?.id;
    const discountEntry = FeaturedItem?.find(entry => entry.productId === productId);
    if (discountEntry) {
      if (discountEntry.maxEarnPoints > 0) {
        return `/ ${parseFloat(discountEntry.maxEarnPoints).toFixed(2)} pts`;
      }
    }
  }
  return (
    <section className="letshop esec-mar pro-grey">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between mb-1 mt-2">
            <div className="first w-70 mb-2">
              <h5 className="profeature">Featured Gift Cards</h5>
            </div>
          </div>
          <div className="featurecards_wrapper">
            {matchedProducts?.map(item =>
              <FeaturedCard
                item={item}
                imgSrc={item?.small}
                title={item?.name}
                symbol={item?.currencySymbol}
                minPrice={item?.minPrice}
                point={calculatePoints(item)}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
};
export default FeaturedGiftCardsSection;

