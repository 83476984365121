import API from "../Common/Endpoint/serviceConstrants";
import axiosIntegration from "../Common/Axios/axiosIntegration";
export const generateTokenApi = async (payload) => {
    const { data = {} } = await axiosIntegration.post(API.generate_token, {
      "partnerCode": process.env.REACT_APP_PARTNER_CODE_API,
      "accessKey": 'kotak@#$gsapi@sapi',
      "secrateKey": process.env.REACT_APP_SECRET_KEY,
  });
  return data;
};
