/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Star from "../../../src/Assets/Star 62.png";
import { onSavePoints } from "../../Store/Slices/cardDataSlice";
import { getMinPrice } from "../../Common/HOC/Hoc";


function SelectCard({ updatedData, productReducer,setEarnPoints }) {
  const dispatch = useDispatch();
  const pointData = useSelector((state) => state.pointsReducer?.data[0]);
  const priceDenomination = productReducer?.productReducer[0]?.priceDenominations.split(',').map(Number);
  const discount = productReducer?.productReducerDisocount?.customerDiscount;

  const cardData = JSON.parse(sessionStorage.getItem("accountData"));
  const cardInfo = JSON.parse(sessionStorage.getItem("cardInfo"));

  const mergedArray = useMemo(
    () =>
      cardData?.map((item1) => {
        const matchingItem = cardInfo?.find(
          (item2) => item2.identifier === item1.subIdentifier
        );
        return {
          ...item1,
          ...(matchingItem ? { cardDetails: matchingItem } : {}),
        };
      }),
    [cardData, cardInfo]
  );

  const creditCards = useMemo(
    () =>
      mergedArray?.filter(
        (card) => card?.cardDetails?.identifiertype === "1002"
      ),
    [mergedArray]
  );

  // Initialize selectedCard with the value from localStorage or default to the first card
  const [selectedCard, setSelectedCard] = useState(
    () =>
      sessionStorage.getItem("selectedCard") ||
      creditCards?.[0]?.cardDetails?.CardTypeName ||
      null
  );

  useEffect(() => {
    if (selectedCard) {
      sessionStorage.setItem("selectedCard", selectedCard);
    }
  }, [selectedCard]);

  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );
  const minEarnPoints = productDiscount?.find(entry => entry.productId === productReducer?.productReducer[0]?.id);
  const minEarn = minEarnPoints?.minEarnPoints

  const validateLoginTokenData = useSelector((state) => state.validateLoginTokenSliceReducer.data);
  const handleCardChange = (card) => {
    setSelectedCard(card);
  };

  let totalDenomination = 0;
  let totalQuantity = 0;
  let totalPayableAmount = 0;

  updatedData?.forEach((item) => {
    totalDenomination += item.denomination * item.quantity;
    totalQuantity += item.quantity;
    totalPayableAmount += item.you_pay * item.quantity;
  });

  const capitalizeFirstLetter = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const handlePointsChange = (card) => {
    dispatch(onSavePoints({ 
      points: Math.round(card?.memberBalance?.pointBalance),
      identifierType: card?.identifierType,
      subIdentifier:card?.subIdentifier,
     }));
  };

  const earnPoints =()=>{
    var earn = ((totalDenomination - (totalDenomination * discount) / 100) * (minEarn / getMinPrice(priceDenomination))).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })
    setEarnPoints(earn)
    return earn;
  }

  return (
    <div id="selectCardContainer" className="selectCardContainer">
      {((totalDenomination - (totalDenomination * discount) / 100) * (minEarn / getMinPrice(priceDenomination))) !== 0 && (
        <div className="points">
          <p>Points you will Earn</p>
          <span className="points-value d-flex align-item-center">
            <i className="fa-solid fa-star fa-star_icon dstp-star"></i>
            {earnPoints()}
          </span>
        </div>
      )}
      {creditCards.length>0 &&
      <div className={`card-selection ${((totalDenomination - (totalDenomination * discount) / 100) * (minEarn / getMinPrice(priceDenomination)) !== 0 ? "" : "mt-4")}`}>
        <p className="card-selection-title">SELECT CARD</p>
        {creditCards?.map((card) => (
          <div className="card-option" key={card?.cardDetails?.identifier}>
            <input
              type="radio"
              name="card"
              value={card.cardDetails.CardTypeName}
              className="card-checkout-UI card-checkout_radio"
              checked={selectedCard === card.cardDetails.CardTypeName}
              onChange={() => {
                handleCardChange(card.cardDetails.CardTypeName);
                handlePointsChange(card);
              }}
            />
            <label className="card-label card-checkout-UI">
              <span className="card_name">
                {capitalizeFirstLetter(
                  card?.cardDetails?.CardTypeName.length > 10
                    ? card?.cardDetails?.CardTypeName?.substring(0, 10) + "..."
                    : card?.cardDetails?.CardTypeName
                )}
              </span>
              <span className="card-number">
                {card.cardDetails.CardNoLast4Digit}
              </span>
              <span className="points-value points-value_btn ">
                <i className="fa-solid fa-star fa-star_icon dstp-star"></i>{" "}
                {Math.round(card.memberBalance?.pointBalance)}
              </span>
            </label>
          </div>
        ))}
      </div>
      }
    </div>
  );
}

export default SelectCard;
