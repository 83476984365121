import axiosIntegration from '../Common/Axios/axiosIntegration';
import API from '../Common/Endpoint/serviceConstrants';

export const callGetMemberApi = async (payload) => {
  const { data = [] } = await axiosIntegration.get(API.getMemberApi, {
  params:
  {
    memberIdentifier:payload.memberIdentifier,
    identifierType:payload.identifierType,
    optionalIdentifier:payload.optionalIdentifier,
    culture:payload?.culture
  }

  });
  return data;
};