import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../src/Assets/Group 1171283252[1].png";
import { useSelector } from "react-redux";

const TrendingGiftCard = ({
  product,
  uniqueCategory,
  handleShow,
  productValues
}) => {
  const navigate = useNavigate();
  const [notFoundStates, setNotFoundStates] = useState([]);

  const handleSubmit = (item) => {
    navigate(`/giftcard?productId=${item?.id}`, { state: { item } });
  };
  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );

  const getMaxPrice = (priceDenominations) => {
    const pricesArray = priceDenominations.split(',');
    const pricesNumbers = pricesArray.map(Number);
    return Math.max(...pricesNumbers);
  };
  const getMinPrice = (priceDenominations) => {
    const pricesArray = priceDenominations.split(',');
    const pricesNumbers = pricesArray.map(Number);
    return Math.min(...pricesNumbers);
  };

  const calculatePointsern = (item) => {
    const productId = item.id;
    const maxPrice = getMaxPrice(item.priceDenominations);
    const minPrice = getMinPrice(item.priceDenominations);
    const discountEntry = productDiscount?.find(entry => entry.productId === productId);
    if (discountEntry) {
      if (discountEntry.minEarnPoints > 0) {
        return `Earn upto ${parseFloat(discountEntry.minEarnPoints / minPrice) * (maxPrice - (maxPrice * discountEntry.customerDiscount) / 100)} pts`;
      }
    }
    return null;
  }

   const calculatePoints =(item)=>{
    const productId = item.id;
    const discountEntry = productDiscount?.find(entry => entry.productId === productId);
    if (discountEntry) {
      if (discountEntry.maxEarnPoints > 0) {
        return `/ ${parseFloat(discountEntry.maxEarnPoints).toFixed(2)} pts`;
      }
    }
  }
  const handleShowModal = (item) => {
    handleShow(false);
    productValues(item);
  };

  const handleImageError = (index) => {
    setNotFoundStates((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };
  const [nav, setNav] = useState()
  const clientConfigurationData = useSelector((state) => state.clientConfigurationReducer.clientConfigurationData);
  const client_Id = useSelector((state) => state.loginAuthReducer.data);
  const matchedClientData = clientConfigurationData.find((config) =>
    client_Id?.some(client => client.clientId === config.clientId)
  );
  useEffect(() => {
    setNav(matchedClientData?.enableQuickBy)
  }, [clientConfigurationData, client_Id])
  
  return product?.map((item, index) => (
    <div key={index} className="col-lg-3 col-6 mb-3 pad-0 card_mob_p">
      <div className="giftcardbox trendingcardBox">
        <div onClick={() => handleSubmit(item)}>
          {notFoundStates[index] && (
            <div className="info-icon">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              <div>Error in image path</div>
            </div>
          )}
          <img
            src={notFoundStates[index] ? defaultImage : item?.small}
            onError={() => handleImageError(index)}
            alt={item?.name}
            className="w-100 trendCardImg"
          />
          <h4 className="card-name">{item?.name}</h4>
        </div>
        <div className="fixFromBottom">
          <p className="aapo aapoForMob">
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#earnpoints"
              className="start-point"
              // onClick={() => handleShowModal(item)}
              onClick={(e) => { 
                e.preventDefault(); // Prevents navigation to "#" 
                nav ? handleShowModal(item) : handleSubmit(item);
              }}
            >
              Starting {item?.currencySymbol}
              {item?.minPrice}
              {calculatePoints(item)}
            </Link>
          </p>
          <p className="earn_upto_points">{calculatePointsern(item)}</p>
        </div>
      </div>
    </div>
  ));
};

export default TrendingGiftCard;
