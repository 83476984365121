import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EarnPointsModal from "../ProductList/modal";
import { useSelector } from "react-redux";

const FeaturedCard = ({ item, imgSrc, title, symbol, minPrice, point }) => {
  const [nav, setNav] = useState()
  const [showModal, setShowModal] = useState(false);
  const [enhancedProductReducer, setEnhancedProductReducer] = useState(null);
  const navigate = useNavigate();
  const productReducer = useSelector((state) => state.productReducer?.productByProductId);
  const productDiscount = useSelector(state => state?.ClientProductMappingbyIdReducer?.data?.[0]?.clientProductMapping);
  const handleSubmit = (item) => {
    window.scrollTo(0, 0);
    navigate(`/giftcard?productId=${item?.id}`, { state: { item } });
  };

  const handleClose = () => setShowModal(false);
  const handleShowModal = (item) => {
    setEnhancedProductReducer(item);
    setShowModal(true);
  };

  useEffect(() => {
    if (productReducer && productDiscount) {
      const discount = productDiscount.find(discountItem => discountItem.productId === productReducer[0]?.id);
      const customerDiscount = discount?.customerDiscount;
      setEnhancedProductReducer({
        ...productReducer[0],
        customerDiscount
      });
    }
  }, [productReducer, productDiscount]);

  const clientConfigurationData = useSelector((state) => state.clientConfigurationReducer.clientConfigurationData);
  const client_Id = useSelector((state) => state.loginAuthReducer.data);
  useEffect(() => {
    setNav(clientConfigurationData.enableQuickBy)
  }, [clientConfigurationData, client_Id])
  return (
    <>
      {showModal && (
        <EarnPointsModal
          modalDataValue={enhancedProductReducer}
          show={showModal}
          handleClose={handleClose}
        />
      )}
      <div
        className="owl-item active"
        style={{ width: "251.2px", marginRight: "10px" }}
        onClick={() => handleSubmit(item)}
      >
        <div className="p-md-2 p-1">
          <div className="giftcardbox">
            <img className="w-100" src={imgSrc} alt={imgSrc} />
            <h4 className="gift-card-name">{title}</h4>
            <p className="aapo">
              <Link
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#earnpoints"
                className="start-point"
                onClick={(e) => {
                  e.preventDefault();
                  nav ? handleShowModal(item) : handleSubmit(item);
                }}
              >
                Starting {symbol} {minPrice} {point}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedCard;
