import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GetTranslationData } from '../GetTranslationData/GetTranslationData ';
import crossIcon from './../../Assets/Shape.png'
const ClearFilter = ({product}) => {
    const filters = GetTranslationData("UIAdmin", "filters");
    const location = useLocation();
    const navigate = useNavigate();
    const clearFilter = () =>{
        debugger
        const params = new URLSearchParams(location.search);
        params.delete("categoryId");
        params.delete("discount");
        navigate(`${location.pathname}?${params.toString()}`);
    }
    return (
        <div className="clearfilterbox" >
            <div className="clearfix">
                <div className="clearfilterhead">
                    <div className="row m-n1">
                        <div className="col-xl-7  col-8">
                            <h3 className="fill">{filters}</h3>
                        </div>
                        <div className="col-xl-5 col-4 px-0 clearAll_position">
                            <i className="fa-solid fa-circle-xmark cursorPointer" onClick={()=>{clearFilter()}}></i> &nbsp;
                            <Link id="#" to="#" className="text-right" onClick={()=>{clearFilter()}}>Clear filter</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClearFilter