const API = {
  //Offer Master
  getofferMaster: "/offer-master",
  //Banner Master
  getbannerMaster: "/banner-master-current-active",
  //login
  loginAuth: "/generate-auth-token",
  //translation
  translationApi: "/translation-content-get-by-clientid",
  // create categories
  getAll_categories: "/categories/all",
  // add-special api
  add_special: "add-special",
  //allocate brand
  allocate_Brand: "/allocate-brands",
  allocate_Brand_Id: "/allocate-brands-by-addSpecial-id",
  //supplier-brand-list
  supplier_brandList: "/productsById",
  //CMS
  getcms: "/cms",
  //client-product-mapping-by-client-Id
  getClientProductMappingbyId: "/client-product-mapping-by-client-Id",
  // member
  member: "/member",
  // generate token
  generate_token: "/GenerateToken",
  // validate login token
  validate_login_token: "/sso/ValidateLoginToken",
  //product
  product_By_Id: "/products-by-client-id",

  //productById
  product_By_Product_Id: "/productsById",
  //sessionLogout
  sessionLogout: "/sso/SessionLogOut",

  //static points
  staticPoints: "/static-Points",
  //points
  pointsApi: "/client-configuration",

  //promotional
  promotional_api: "promotional-strip-current-active",
  // clientconfiguration
  clientconfiguration: "/client-configuration",
  clientbyid: "/client-master-by-Id",

  themeManagement: "/theme-management",

  //getMember Api
  getMemberApi: "/GetMember",

    //switch account card api
    switchAccountApi: "/sso/SwitchAccount",
    // segmentId
    segmentApi: "/GetMappedSegmentWithMember",

  // otp-process
  sendOtp: "/SendOtp",
  validateOtp: "/ValidateOtp",

  //order
  order: "/order",
  memberOrder : "/order-by-memberId"
};
export default API;
