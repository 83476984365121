import { createSlice } from "@reduxjs/toolkit";

export const cardData = createSlice({
  name: "card_data",
  initialState: {
    getcardData: [],
  },
  reducers: {
    onSavePoints: (state,{payload}) => {
      return {
        ...state,
        points: payload?.points,
        identifierType: payload?.identifierType,
        subIdentifier: payload?.subIdentifier,
      };
    },
  },
});

export const { onSavePoints } = cardData.actions;

export default cardData.reducer;
