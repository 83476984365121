export const config = [
  {
    API_URL: "https://qa-cc.giftstacc.com",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
  },
  {
    API_URL: "https://uat-cc.giftstacc.com",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
  },
  {
    API_URL: "https://bankofmaharashtra.giftstacc.com/",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
  },
  {
    API_URL: "http://qa-bom.giftstacc.com",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
  },
  {
    API_URL: "https://qa-bom.giftstacc.com",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
  },
  {
    API_URL: "http://localhost:3000",
    ACCESS_KEY: "3",
    SECRET_KEY: "3",
    PARTNER_KEY: "UIClient",
  }]