import React, { useState } from "react";
import insicon from "../../Assets/insicon.png";
import redicon from "../../Assets/redicon.png";
import ModalComponent from "./Modal.js";
import { Link } from "react-router-dom";
import defaultImage from "../../../src/Assets/Group 1171283252[1].png";

const GiftCardDetailsSection = (productDetails) => {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("terms");

  const handleClose = () => setShowModal(false);
  const handleShow = (event, tab) => {
    event.preventDefault();
    setActiveTab(tab);
    setShowModal(true);
  };
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <section className="letshop bg-white pb-0Mob">
      <div className="container big-rr mb-2">
        {showModal && (
          <ModalComponent
            productDetails={productDetails}
            show={showModal}
            handleClose={handleClose}
            activeTab={activeTab}
          />
        )}
        <div className="row justify-content-center p-2">
          <div className="col-lg-4 plusminus mb-2">
            <div className="img-cart text-center">
                <img
                  className="w-100 h-gift gift_card_image_radius"
                  style={{ width: "410px", height: "268px" }}
                  src={productDetails?.productReducer?.mobile || defaultImage}
                  alt="Coupon"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultImage;
                  }}
                />
            </div>
          </div>
          <div className="col-lg-8 mt-2 pl-25">
            <div
              id="carouselBrandControls"
              className="carousel slide"
              data-bs-ride="false"
              data-bs-touch="false"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="mb-3">
                    <h4 className="mb-0 fnt-bold procardname">
                      {productDetails.productReducer?.name}
                    </h4>
                    <span className="pro-discount">
                      {productDetails?.productReducer?.customerDiscount !== 0 &&
                        `Discount Flat ${productDetails?.productReducer?.customerDiscount}%`}
                    </span>
                    <span className="Redeemable_online"><span><i className="fa-solid fa-check check_icon"></i></span>Redeemable Online</span>
                  </div>
                  <div>
                    <div className="fs-14 pt-md-0">
                    <p className={`pro-discrip ${expanded ? "expanded" : ""}`}>
  {expanded
    ? productDetails?.productReducer?.description
    : `${productDetails?.productReducer?.description?.substring(0, 208)}${productDetails?.productReducer?.description?.length > 100 ? "..." : ""}`}
  {!expanded && (
    <span className="show-more" onClick={toggleExpanded}>
      Show More
    </span>
  )}
</p>
                      {expanded && (
                        <span className="show-less" onClick={toggleExpanded}>
                          Show Less
                        </span>
                      )}
                    </div>
                    <div className="d-grid gap-2 imp-btns d-flex justify-content-start mt-4">
                      <Link
                        onClick={(e) => handleShow(e, "terms")}
                        className="de-counterins termsBtn"
                      >
                        <img className="w-28px mr-2" src={insicon} />
                        T&C and Instructions
                      </Link>

                      <Link
                        onClick={(e) => handleShow(e, "redeem")}
                        className="de-counterins forMobViewReedem"
                      >
                        <img className="w-28px mr-2 d-none d-lg-inline" src={redicon} />
                        <span className="redeem_text">How to Redeem ?</span>
                        <span className="redeem_arrow d-lg-none d-md-none"><i className="fa-solid fa-chevron-right"></i></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiftCardDetailsSection;
