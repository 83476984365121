/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CategoryFilter from "./CategoryFilter";
import DiscountFilter from "./DiscountFilter";
import ClearFilter from "./ClearFilter";
import TrendingCardHeader from "./TrendingCardHeader";
import TrendingGiftCard from "./TrendingGiftCard";
import { useSelector } from "react-redux";
import { GetTranslationData } from "../GetTranslationData/GetTranslationData ";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import EarnPointsModal from "./modal";
import { useRef } from "react";
import SortBy from "./SortBy";


const ProductList = () => {
  const navigate = useNavigate();

  const back = GetTranslationData("UIAdmin", "back");
  const [sortByData, setSortByData] = useState("sortBy");
  const [displaySort, setDisplaySort] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dispalyFilter, setDisplayFilter] = useState(false);
  const [products, setProducts] = useState();
  const [allProducts, setAllProducts] = useState();
  const [newPriceRange, setNewPriceRange] = useState([]);
  const [newPointRange, setNewPointRange] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [discount, setDiscount] = useState();
  const [selectedSort, setSelectedSort] = useState("");
  const productData = useSelector(
    (state) => state.productReducer.productById?.[0]?.products
  );
  const location = useLocation();
  const filterData = location?.state?.productData;

  const productDiscount = useSelector(
    (state) =>
      state?.ClientProductMappingbyIdReducer.data?.[0]?.clientProductMapping
  );
  const trendingProducts = useSelector(
    (state) => state?.trendingGiftCardsReducer?.data[0]?.products
  );

  const openModal = () => {
    setIsOpen(true);
  };
  const handleSortChange = (sortedProducts) => {
    setProducts(sortedProducts);
  };

  // Function to handle clearing sort
  const handleClearSort = () => {
    setSelectedSort("");
    // setProducts(allProducts); // Reset products to original list
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  let AllProductData = location?.state?.isTrending ? filterData : productData;
  useEffect(() => {

    if (AllProductData && productDiscount) {

      const ProductDataWithDiscount = AllProductData.map((product) => {
        const discount = productDiscount.find(
          (data) => data.productId === product.id
        );
        if (discount) {
          return { ...product, customerDiscount: discount.customerDiscount };
        }
        return product;
      });
      setAllProducts(ProductDataWithDiscount);
    }
  }, [productDiscount,AllProductData]);

  const modalRef = useRef(null);

  // Toggle modal visibility
  const toggleModal = () => {
    setDisplaySort((prev) => !prev);
  };

  // Handle clicks outside the modal
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setDisplaySort(false);
      setDisplayFilter(false);
    }
  };

  // Add event listener on mount and cleanup on unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // get checked product data in category
  const handleCategory = (category, name) => {
    setCategory(category);
    setCategoryName(name);
  };
  // get discount which is selected
  const handleDiscount = (newDiscount) => {
    setDiscount(newDiscount);
  };

  function filterProductsByPrice(products, mn, max) {
    return products.filter((product) => {
      const prices = product.priceDenominations.split(",").map(Number);
      return prices.some((price) => price >= mn && price <= max);
    });
  }
  function filterProductsByPoint(products, min, max) {
    return products.filter((product) => {
      const points = product.priceDenominations.split(",").map(Number);
      return points.some((point) => point >= min && point <= max);
    });
  }

  useEffect(() => {
    let filteredProducts = allProducts;

    if (newPriceRange.length === 2) {
      const [minPrice, maxPrice] = newPriceRange;
      filteredProducts = filterProductsByPrice(
        filteredProducts,
        minPrice,
        maxPrice
      );
    }
    if (newPointRange.length === 2) {
      const [minPoint, maxPoint] = newPointRange;
      filteredProducts = filterProductsByPoint(
        filteredProducts,
        minPoint,
        maxPoint
      );
    }

    if (category.length > 0) {
      const categoryIds = category.map((item) => item.id);
      filteredProducts = filteredProducts?.filter((product) =>
        categoryIds.includes(product.id)
      );
    }

    if (discount > 0) {
      filteredProducts = filteredProducts?.filter(
        (product) => product?.customerDiscount >= discount
      );
    }
    if (selectedSort === "alphabet") {
      filteredProducts = [...filteredProducts].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }
    setProducts(filteredProducts);
  }, [
    newPriceRange,
    newPointRange,
    category,
    discount,
    allProducts,
    trendingProducts,
    selectedSort,
  ]);

  // get name of the sortBy in sortByData
  const handleSortBy = (item) => {
    setSortByData(item);
    setDisplaySort(false); // Close modal after sorting
    setDisplayFilter(false);
  };
  useEffect(() => {
    if (sortByData === "price-low-to-high") {
      const lowToHighProducts = [...products].sort(
        (a, b) => parseInt(a?.minPrice) - parseInt(b?.minPrice)
      );
      setProducts(lowToHighProducts);
    } else if (sortByData === "price-high-to-low") {
      const highToLowProducts = [...products].sort(
        (a, b) => parseInt(b?.minPrice) - parseInt(a?.minPrice)
      );
      setProducts(highToLowProducts);
    } else if (sortByData === "pts-high-to-low") {
      const highToLowPoints = [...products].sort(
        (a, b) => parseInt(b?.minPrice) - parseInt(a?.minPrice)
      );
      setProducts(highToLowPoints);
    } else if (sortByData === "pts-low-to-high") {
      const lowToHighPoints = [...products].sort(
        (a, b) => parseInt(a?.minPrice) - parseInt(b?.minPrice)
      );
      setProducts(lowToHighPoints);
    } else if (sortByData === "discount-low-to-high") {
      const discountLowtoHighProducts = [...products].sort(
        (a, b) => a?.customerDiscount - b?.customerDiscount
      );
      setProducts(discountLowtoHighProducts);
    } else if (sortByData === "discount-high-to-low") {
      const discountHightoLowProducts = [...products].sort(
        (a, b) => b?.customerDiscount - a?.customerDiscount
      );
      setProducts(discountHightoLowProducts);
    } else {
      setProducts(products);
    }
  }, [sortByData]);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [modalData, setModalData] = useState();

  const handleModalData = (item) => {
    setModalData(item);
  };
  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const applyFilters = () => {
    let filteredProducts = allProducts;

    if (newPriceRange.length === 2) {
      const [minPrice, maxPrice] = newPriceRange;
      filteredProducts = filterProductsByPrice(
        filteredProducts,
        minPrice,
        maxPrice
      );
    }
    if (newPointRange.length === 2) {
      const [minPoint, maxPoint] = newPointRange;
      filteredProducts = filterProductsByPoint(
        filteredProducts,
        minPoint,
        maxPoint
      );
    }

    if (category.length > 0) {
      const categoryIds = category.map((item) => item.id);
      filteredProducts = filteredProducts.filter((product) =>
        categoryIds.includes(product.id)
      );
    }

    if (discount > 0) {
      filteredProducts = filteredProducts.filter(
        (product) => product?.customerDiscount >= discount
      );
    }

    // Update the products state with the filtered products
    setProducts(filteredProducts);

    // Close the modal after applying filters
    closeModal();
  };

  return (
    <>
      <ScrollToTop />
      <section className="py-xl-3 pt-1 pb-2 mar-4 desktop-hide">
        <div className="container-fluid">
          {showModal && (
            <EarnPointsModal
              modalDataValue={modalData}
              show={showModal}
              handleClose={handleClose}
            />
          )}
          <div className="m-xl-n3 m-md-n2 m-n1">
            <div className="p-md-2 p-1 back" onClick={handleClick}>
              <i class="fa-solid fa-chevron-left clr-black-blk"></i> &nbsp;
              {back}
            </div>
          </div>
        </div>
      </section>
      <section className="py-xl-3 pt-1 pb-2 mt-5rems pt-6">
        <div className="container">
          <div className="row listpagerow">
            <div className="col-xl-3 col-12 fil-list">
              <div className="listfilterpadd bg-w-p2 mb-2">
                <ClearFilter product={products} />
                <CategoryFilter categoryName={handleCategory} />
                <hr />
                <DiscountFilter
                  product={allProducts}
                  discount={handleDiscount}
                />
                <SortBy
                  products={products}
                  onSortChange={handleSortChange}
                  onClearSort={handleClearSort}
                  allProducts={allProducts}
                />
              </div>
            </div>
            <div className="col-xl-9 col-12 ">
              <div className="row jcc-mob">
                <TrendingCardHeader
                  product={products}
                  categoryName={categoryName}
                  sortBy={handleSortBy}
                />
                <div className="row m-xl-n3 m-md-n2 m-n1 mt-4 listpagerow  p-trend">
                  <TrendingGiftCard
                    productValues={(item) => handleModalData(item)}
                    handleShow={handleShow}
                    product={products}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Mobile and tab sort and filter  */}
      {!isOpen && !showModal && (
        <div className="listheader desktop-hide" style={{ zIndex: 999999999 }}>
          <nav className="bottom-nav">
            <div className="bottom-nav-item">
              <div
                className="bottom-nav-link"
                // onClick={() => setDisplaySort(!displaySort)}
                onClick={openModal}
              >
                <Link data-bs-toggle="modal" data-bs-target="#sortby">
                  <i className="fas fa-sliders-h filter-icon">&nbsp;</i>
                </Link>
                <span className="mobile-filter">Filter and Sort</span>
              </div>
            </div>
          </nav>
        </div>
      )}
      {/* sort by modal pop pup for mobile and tab */}
      <div>
        {/* Button to toggle modal */}
        {/* <button onClick={toggleModal}>Sort By</button> */}

        {/* Modal */}
        {displaySort && (
          <div
            className={`modal fade ${displaySort ? "filterShowHide" : ""}`}
            id="sortby"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{
              position: "fixed",
              top: "calc(100vh - 309px)",
              width: "100%",
            }}
            ref={modalRef}
          >
            <div className="modal-dialog bordertop">
              <div className="modal-content bordertop">
                <div
                  className="modal-header borderBottomNone"
                  onClick={toggleModal}
                >
                  <span className="modal-btn"></span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* filter modal pop pup for mobile and tab */}
      <div>
        {isOpen && (
          <div className="custom-modal-overlay" onClick={closeModal}>
            <div
              className="custom-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="fixtop">
                <ClearFilter product={products} />
              </div>
              <div className="mb-2">
                <CategoryFilter categoryName={handleCategory} />
                <hr />
                <DiscountFilter
                  product={allProducts}
                  discount={handleDiscount}
                />
                <div style={{ marginBottom: "4.5rem" }}>
                  <SortBy
                    products={products}
                    onSortChange={handleSortChange}
                    onClearSort={handleClearSort}
                    allProducts={allProducts}
                  />
                </div>

                <div className="buttons-fixed">
                  <div className="col-lg-12 col-12 mt-2 text-center d-flex justify-content-center">
                    <button
                      className={`filter-button`}
                      onClick={closeModal}
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>

                    <Link
                      className={`filter-button ${
                        category?.length > 0 ? "selected" : ""
                      }`}
                      onClick={applyFilters}
                      style={{ cursor: "pointer" }}
                    >
                      Apply
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductList;
