import API from "../Common/Endpoint/serviceConstrants";
import axiosIntegrationOtp from "../Common/Axios/axiosIntegrationOtp";
export const sendOtpApi = async (payload, token) => {
    const { data = {} } = await axiosIntegrationOtp.post(API.sendOtp, payload, {
        headers: {
        "access-token": token
        }
    });
  return data;
};
