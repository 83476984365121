import React from "react";
import FooterLogo from "../../Assets/main-logo.png";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  const clientConfig = useSelector((state) => state.clientConfigurationReducer?.clientConfigurationData?.[0]);
  const location = useLocation();

  if (location.pathname.endsWith("/otp")||location.pathname.endsWith("/myprofile")) {
    return null;
  }
  const login = JSON.parse(sessionStorage.getItem("login"));

  return (
    <>
      <footer className="footer new-ft">
        <div className="top-footer">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                <div className="footlist">
                  <div className="footsocial">
                    <img className="kok" src={FooterLogo} alt="img" />
                    <ul>
                      <li className="d-none">
                        <Link target="_blank">
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="d-none">
                        <Link target="_blank">
                          <i className="lab la-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div className="footlist">
                      <Link
                        to="tel:+91 9999999999"
                        className="clr-white fnt-13"
                      >
                        <i
                          className="las la-phone-alt"
                          style={{ transform: "rotate(-85deg)" }}
                          // style="transform: rotate(-85deg);"
                        ></i>
                        &nbsp;{clientConfig?.phoneNumber}
                      </Link>
                      <br />
                      <Link
                        to="mailto:support@cc.giftstacc.com"
                        className="clr-white fnt-13"
                      >
                        <i className="las la-envelope"></i>
                        &nbsp;{clientConfig?.email}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                <div className="footlist">
                  <h4>About</h4>

                  <ul className="footul">
                    <li>
                      <Link to="/static/Our Story">Our Story</Link>
                    </li>
                    <li>
                      <Link to="/static/Vendor Panel">Vendor Panel</Link>
                    </li>

                    <li>
                      <Link to="#">Blog</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                <div className="footlist">
                  <h4>Help</h4>
                  <ul className="footul">
                    <li>
                      <Link to="/contact-us">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/static/Terms and Conditions">
                        Terms and Conditions
                      </Link>
                    </li>

                    <li>
                      <Link to="/static/Cancellation & Returns Policy">
                        Cancellation &amp; Returns Policy
                      </Link>
                    </li>

                    <li>
                      <Link to="/static/Privacy Policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                <div className="footlist">
                  <h4>{login ? "My Account" : ""}</h4>
                  <ul className="footul">
                    {login ? (
                      <>
                        <li>
                          <Link to="/orders">My Orders</Link>
                        </li>
                        <li>
                          <Link to="/myprofile">My Profile</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="footlogos d-none">
              <div className="row justify-content-center">
                <div className="col-xl-12 col-md-12 col-12">
                  <div className="copytextbox text-center">
                    <p>
                      Copyright © 2024 Customer Capital. All Rights Reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
