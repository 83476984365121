import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import yourCard from "../../Assets/youregift.png";
import qr from "../../Assets/qrcode.png";
import upright from "../../Assets/upright.png";
import amazon from "../../Assets/ama.png";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onGetOrder } from '../../Store/Slices/orderSlice';

const GiftcardSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [products,setProducts] = useState([]);
  const [products1,setProducts1] = useState([]);
  const [products12,setProducts12] = useState([]);
  const validateLoginTokenData = useSelector(
    (state) => state.validateLoginTokenSliceReducer.data
  );
  const userOrderData = useSelector(
    (state) => state.orderReducer
  );

  const [expandedIndex, setExpandedIndex] = useState([]);
  useEffect(() => {
    dispatch(onGetOrder(validateLoginTokenData.memberIdentifier));
  }, []);
  useEffect(()=>{
    debugger
const url = new URL(window.location.href);
const orderIdToFilter = url.searchParams.get('orderID');
const orderDenoMination = url.searchParams.get('denomination');
const filteredData = Array.isArray(userOrderData.orderData) && userOrderData.orderData.filter(order => order.orderId === orderIdToFilter && order.denomination===parseInt(orderDenoMination))
const dataToMap=filteredData?.[0]?.activationCode.slice(0,-1).split(';');
const dataToMaps= filteredData?.[0]?.activationUrl.slice(0,-1).split(';');
    
    setProducts(filteredData)
    setProducts1(dataToMap)
    setProducts12(dataToMaps)
  },[userOrderData])

  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setExpandedIndex(products.map((_, index) => index)); 
    } else {
      setExpandedIndex([]);
    }
  };

  // toggle expand for individual items
  const toggleExpand = (index) => {
    if (expandedIndex.includes(index)) {
      setExpandedIndex(expandedIndex.filter((i) => i !== index)); // Close if already expanded
    } else {
      setExpandedIndex([...expandedIndex, index]); // Open if not expanded
    }
  };

  useEffect(() => {
    handleResize(); // Check on mount
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCopy = (product) => {
    navigator.clipboard.writeText(product);
  };


  const handleNavigate = () => {
   // Handle the scenerio 
  }
  return (
    <div className='bg'> 
      <section className="mt-4 m-2">
        <div className="container p-2">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-12">
              <div className="bg-pink1">
                <div className="p-2">
                  <div className="thankyoubox2">
                    <p className="onepgift">
                      <img src={yourCard} alt="Your E-Gift Cards" /> &nbsp;Your E - Gift Cards
                    </p>
                    <p className="egift-dis">
                      The E-Gift card codes have been sent to your Bank Of Maharastra registered mobile number and email id. You will receive an email from ………..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className="m-2 mt-4">
        <div className="container p-2">
          <div className="row justify-content-center">
            {Array.isArray(products1) && products1?.map((product, index) => (
              <div className="col-lg-5 col-12 mb-4" key={index}>
                <div className="accordion" id="accordionExample">
                  <div className="its-card border-rad">
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={expandedIndex.includes(index) ? "true" : "false"}
                        aria-controls={`collapse${index}`}
                        onClick={() => toggleExpand(index)}
                      >
                        <div className="gift-wow">
                          <span className="gift-pri">₹{products[0].value}</span>
                          <span>
                           {products[0].name}
                          </span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${expandedIndex.includes(index) ? "show" : ""} mt-2`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="d-flex justify-content-between">
                          <div className="copycode">
                            <p className="codetitle">E gift card code</p>
                            <p className="giftcode1">
                              {product} &nbsp;<span><i className="fa-regular fa-copy cursorPointer" onClick={(e)=>handleCopy(product)}></i></span>
                            </p>
                          </div>
                          <div className="qrcodeimg">
                            <img className="qr-sz" src={qr} alt="QR Code" />
                          </div>
                        </div>

                        <div className="d-flex justify-content-between mt-3 mb-3">
                          <div className="copycode">
                            <p className="codetitle">GC ID</p>
                            <p className="giftcode">{products12[index]}</p>
                          </div>
                          <div className="copycode txt-right txt-dir">
                            <p className="codetitle">Exp date</p>
                            <p className="giftcode">23/01/2025</p>
                          </div>
                        </div>

                        <hr />

                        <div className="d-flex justify-content-between">
                          <div className="copycode" onClick={handleNavigate}>
                            <p className="gifttry">
                              Visit Now &nbsp; <img src={upright} alt="Try" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GiftcardSummary;
